import GATSBY_COMPILED_MDX from "/Users/kit/Code/projects/portfolio/src/pages/projects/raws/simplygopal.mdx";
import React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout";
import * as project from "../../styles/projects/index.module.scss";
var query = "414652676";
var Post = function Post(_ref) {
  var data = _ref.data, children = _ref.children;
  var frontmatter = data.mdx.frontmatter;
  return React.createElement(Layout, null, React.createElement("div", {
    className: project.wrapper
  }, React.createElement("div", {
    className: project.frontmatter
  }, React.createElement("h1", {
    className: project.title
  }, React.createElement(Link, {
    to: "/projects"
  }, "projects /"), React.createElement("span", null, frontmatter.title, " | ", frontmatter.byline)), React.createElement("div", {
    className: project.referBtns
  }, React.createElement("a", {
    href: frontmatter.github
  }, React.createElement("button", null, "/github")), frontmatter.demo && React.createElement("a", {
    href: frontmatter.demo
  }, React.createElement("button", null, "/demo")))), React.createElement("div", {
    className: project.body
  }, children)));
};
Post
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
